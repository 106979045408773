@media (max-width: 899.95px) {
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    flex-basis: auto;
  }
}

.RaSidebar-fixed a.RaMenuItemLink-active {
  color: #000;
  font-weight: 800;
}

.RaSidebar-fixed a.RaMenuItemLink-active  .MuiSvgIcon-root {
  color: #000;
}
